<template>

<app-page class="home">

	<div class="home-content">

		<div class="home-content-prefix" v-if="!window.is.mobile">Welcome to the</div>

		<h1 class="home-content-title" v-if="!window.is.mobile">Inhalers Patient Decision Aid</h1>

		<div class="home-content-text" v-html="content.text" />

		<app-button theme="white" v-on:click="onStartClick" text="Start" class="home-content-button" />

		<div class="home-content-disclaimer" v-html="content.disclaimer" />
		
		<div class="home-content-code" v-on:click="onCodeClick" v-if="!is.entering">Have a recommendation code? <span>Click here</span> to enter it.</div>

		<div class="home-content-verify" :class="{'is-failed': is.failed}" v-if="is.entering">

			<input type="text" v-model="code" ref="verify" maxlength="8" v-on:keydown="onCodeKey" class="home-content-verify-input" placeholder="Enter your code..." />
			<div class="home-content-verify-button" :class="{'is-disabled': code.length < 8}" v-on:click="onCodeVerifyClick">Verify</div>

		</div>

		<div class="home-content-share" v-on:click="onShareClick">

			<div class="home-content-share-about" v-if="!is.sharing">
				If you are a healthcare professional and you want to use the 'Inhalers PDA' as part of a remote consultation you have the option to email or text (SMS) the patient a link to an online version of this decision aid. If you would like to create and send a link to the patient please <span>click here</span> before starting the consultation.
			</div>

			<div class="home-content-share-about" v-if="is.sharing">
				Enter an email address of a mobile phone number into the box below and click the 'Share' button to send the URL to the patient.
			</div>

			<div class="home-content-verify" :class="{'is-failed': is.failed, 'is-success': is.sent}" v-if="is.sharing">

				<input type="text" v-model="share" ref="share" v-on:keydown="onShareKey" :disabled="is.sent" class="home-content-verify-input" placeholder="Enter email or number..." />
				<div class="home-content-verify-button" :class="{'is-disabled': !isShareValid || is.sent}" v-on:click="onShareSendClick">Share</div>

			</div>

		</div>


	</div>

	<div class="home-foot">

		<div class="home-foot-inner" v-html="content.foot" />

	</div>

</app-page>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				entering: false,
				verifying: false,
				failed: false,
				sharing: false,
				sending: false,
				sent: false
			},
			code: '',
			share: ''
		}

	},

	computed: {

		content: function() {

			return this.$store.getters['data'].home

		},

		isShareValid: function() {

			return this.share.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || /^(?:\W*\d){11}\W*$/.test(this.share)

		}

	},

	methods: {

		onStartClick: function() {

			this.$store.commit('answers', [])
			
			this.$router.push({
				name: 'Questions'
			})

		},

		onCodeKey: function(e) {

			if (e.key === 'Enter') this.onCodeVerifyClick()

		},

		onShareKey: function(e) {

			if (e.key === 'Enter') this.onShareSendClick()

		},

		onCodeVerifyClick: function() {

			if (this.code.length === 8 && !this.is.verifying) this.onCodeVerify()

		},

		onShareSendClick: function() {

			if (this.isShareValid && !this.is.sending) this.onShareSend()

		},

		onShareSend: function() {

			this.is.sending = true
			this.is.failed = false

			this.$store.dispatch('share', this.share).then(function() {

				this.is.sent = true

			}.bind(this))

		},

		onCodeVerify: function() {

			this.is.verifying = true
			this.is.failed = false

			this.$store.dispatch('verify', this.code).then(function(response) {

				if (response.key) {

					this.$router.push({
						name: 'Recommendation'
					})

				} else {

					this.is.verifying = false
					this.is.failed = true

				}

			}.bind(this))

		},

		onCodeClick: function() {

			this.is.entering = true

			this.$nextTick(function() {

				this.$refs.verify.focus()

			}.bind(this))

		},

		onShareClick: function() {

			this.is.sharing = true

			this.$nextTick(function() {

				this.$refs.share.focus()

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.home {
	background-image: url(~@/assets/home.hero.png);
	background-size: 1850px 1827px;
	background-position: calc(50% + 450px) -492.13006591796875px;
	background-repeat: no-repeat;
}

.is-mobile .home {
	background-size: auto 600px;
	background-position: calc(100% + 150px) -170px;
}

.home-content {
	max-width: 516px;
	width: 100%;
	background-image: url(~@/assets/logo.png);
	background-repeat: no-repeat;
	background-size: 339px 77px;
	background-position: 0px 89px;
	padding-top: 252px;
	padding-bottom: 40px;
	margin: 0px auto 0px auto;
	transform: translateX(-222px);
	min-height: 920px;
}

.is-mobile .home-content {
	padding-top: 400px;
	min-height: auto;
	background-size: 287px auto;
	background-position: 50% 309px;
	transform: none;
	margin: 0px;
	padding-left: 10px;
	padding-right: 10px;
}

.home-content-prefix {
	color: #343434;
	font-size: 30px;
	letter-spacing: 3px;
	margin-bottom: 10px;
	font-weight: 300;
	text-transform: uppercase;
}

.is-mobile .home-content-prefix {
	font-size: 18px;
	text-align: center;
}	

.home-content-title {
	color: #343434;
	font-size: 52px;
	line-height: 55px;
	font-weight: 700;
	margin-bottom: 40px;
}

.is-mobile .home-content-title {
	font-size: 28px;
	text-align: center;
	line-height: 34px;
}

.home-content-disclaimer {
	font-size: 14px;
	margin-top: 20px;
	font-weight: 300;
}

.home-content-text >>> p {
	margin-bottom: 20px;
	line-height: 22px;
	font-size: 18px;
}

.home-content-text >>> ul {
	margin-bottom: 20px;
	list-style: disc;
	list-style-position: outside;
	margin-left: 16px;
}

.home-content-text >>> li {
	line-height: 22px;
	font-size: 18px;
	padding-left: 10px;
}

.home-content-button {
	margin: 0px;
}

.is-mobile .home-content-button {
	margin: 0px auto;
}

.home-foot {
	background-color: #343434;
	color: #fff;
	padding: 40px 0px;
}

.is-mobile .home-foot {
	padding: 20px 10px;
	width:100%;
}

.home-foot >>> p {
	margin-bottom: 10px;
	font-weight: 300;
	line-height: 16px;
	font-size: 13px;
}

.home-foot >>> a {
	text-decoration: underline;
}

.home-foot >>> hr {
	height: 1px;
	border: 0px;
	margin: 20px 0px;
	border-top: 1px solid #fff;
}

.home-foot-inner {
	margin: 0px auto;
	max-width: 960px;
}

.home-content-code {
	font-size: 14px;
	margin-top: 20px;
	font-weight: 300;
	cursor: pointer;
}

.home-content-code span {
	color: #23CFFD;
}

.home-content-code:hover span {
	text-decoration: underline;
}

.home-content-verify {
	display: flex;
	width: 100%;
	margin-top: 20px;
}

.home-content-verify-input {
    background-color: #fff;
    color: #343434;
    border: 2px solid #23CFFD;
	border-radius: 20px 0px 0px 20px;
	height: 40px;
	padding: 0px 20px;
	flex-grow: 1;
	font-size: 16px;
}

.home-content-verify-button {
	color: #fff;
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	padding: 0px 20px 0px 15px;
	line-height: 40px;
    background-color: #23CFFD;
	border-radius: 0px 20px 20px 0px;
}

.home-content-verify-button.is-disabled {
	opacity: 0.6;
	pointer-events: none;
}

.home-content-share {
    background-color: #23CFFD;
	color: #fff;
	margin-top: 50px;
	padding: 20px;
	border-radius: 20px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	cursor: pointer;
}

.home-content-share span {
	font-weight: 700;
}

.home-content-share .home-content-verify-input {
	border-color: #1989a7;
}

.home-content-share .home-content-verify-button {
	background-color: #1989a7;
}

.home-content-verify.is-failed .home-content-verify-input {
	border-color: #fd2323;
}

.home-content-verify.is-failed .home-content-verify-button {
	background-color: #fd2323;
}

.home-content-verify.is-success .home-content-verify-input {
	border-color: #58C145;
}

.home-content-verify.is-success .home-content-verify-button {
	background-color: #58C145;
}

</style>
